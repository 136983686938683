import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Inquire from '../components/Inquire';

import { z, bz } from '../consts/style';
import mq from '../style/mq';
import InquireSoldOut from '../components/InquireSoldOut';

const Wrapper = styled.div`
  transition: all 1s;
  background: white;
  padding-top: 8rem;
  margin-bottom: 36rem;
  z-index: ${z.page};
  ${bz};
  &.shifted {
    padding-top: calc(100vh - 8rem);
  }
  ${mq.mobile`
  margin-bottom: 55.3rem;
  padding-top: 6rem;
`}
`;

export default function PageWrapper({ shiftedDown, children }) {
  return (
    <Wrapper className={shiftedDown && 'shifted'}>
      {children}
      <Inquire />
      <InquireSoldOut />
    </Wrapper>
  );
}

PageWrapper.propTypes = {
  shiftedDown: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
