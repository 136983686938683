import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import social from '../images/social.jpg';

function SEO({ description, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          property: 'og:title',
          content: title,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:image',
          content: social,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: social,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SEO;
