import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Form from './Form';
import { realtor, inquire, realtor2 } from '../consts/data';
import { font } from '../consts/style';
import Sothebys from '../images/sothebys.jpg';
import mq from '../style/mq';
import Fade from './Fade';
import { ContainerCompact } from './Elements';

const Wrapper = styled(ContainerCompact)`
  width: 100%;
  display: flex;
  justify-content: center;
  .container {
    padding: 8rem 0;
    display: flex;
    justify-content: center;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 100%;
    }
    .right {
      max-width: 100%;
    }
  }
`;
const RealtorWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6.4rem;
  width: 30rem;
  max-width: 100%;
  img {
    width: 14rem;
    max-width: 100%;
  }
  ${mq.mobile`
    width: 100%;
  `}
  @media screen and (max-width: 325px) {
    flex-wrap: wrap;
    img {
      margin-top: 2rem;
    }
  }
  &.m-only {
    display: none;
    ${mq.mobile`
      display: flex;
    `}
  }
  &.d-only {
    ${mq.mobile`
      display: none;
    `}
  }
  .block {
    margin-bottom: 2rem;
  }
  p {
    margin: 0;
    padding-bottom: 0.4rem;
    &:last-child {
      padding: 0;
    }
    ${font.p};
  }
`;
const TitleWrap = styled.div`
  ${mq.mobile`
      margin-bottom: 3.2rem;
    `}
  p {
    ${font.pCaps};
    margin-top: 2rem;
    margin-bottom: 12rem;
    ${mq.mobile`
      margin-bottom: 6rem;
    `}
  }
  h3 {
    ${font.h3};
  }
`;

export default function Inquire() {
  return (
    <Wrapper className="row" id="inquire">
      <div className="container row col-12 col-t-12 col-ts-12 col-m-12">
        <div className="section left col-6 col-t-6 col-ts-6 col-m-12">
          <Fade>
            <Title />
            <RealtorDetails c="d-only" />
          </Fade>
        </div>
        <div className="section right col-6 col-t-6 col-ts-6 col-m-12">
          <Fade>
            <Form />
            <RealtorDetails c="m-only" />
          </Fade>
        </div>
      </div>
    </Wrapper>
  );
}

const Title = () => (
  <TitleWrap>
    <h3>{inquire.body1}</h3>
    <h3>{inquire.body2}</h3>
    <p>{inquire.cta}</p>
  </TitleWrap>
);

const RealtorDetails = ({ c }) => (
  <RealtorWrap className={c}>
    <div className="text">
      <div className="block">
        <p>
          <strong>{realtor.name}</strong>
        </p>
        <p>{realtor.title}</p>
        <p>{realtor.dre}</p>
        <p>{realtor.phone}</p>
      </div>
      <div className="block">
        <p>
          <strong>{realtor2.name}</strong>
        </p>
        <p>{realtor2.title}</p>
        <p>{realtor2.dre}</p>
      </div>
    </div>
    <div className="img">
      <img src={Sothebys} alt="Sothebys logo" />
    </div>
  </RealtorWrap>
);

RealtorDetails.propTypes = {
  c: PropTypes.string,
};
