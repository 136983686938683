import React from 'react';
import PropTypes from 'prop-types';

export default function Fade({ children }) {
  return (
    <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="150"
      data-sal-easing="ease"
      className="fade"
    >
      {children}
    </div>
  );
}

Fade.propTypes = {
  children: PropTypes.node.isRequired,
};
