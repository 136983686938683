import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ModalContext from '../store/modalContext';
import { colors, font, fontOffset, bz } from '../consts/style';
import { contact, mailchimp } from '../consts/endpoints';

import CheckSrc from '../images/box-checked.svg';
import UncheckSrc from '../images/box-unchecked.svg';
import axios from 'axios';

const Wrapper = styled.form`
  transition: opacity 0.5s;
  ${bz};
  &.loading {
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
  }
  .checker {
    display: flex;
    align-items: center;
    span {
      font-size: 1.4rem;
    }
    .check-img {
      margin-right: 1.2rem;
      button {
        padding: 0;
        background: transparent;
        border: none;
      }
    }
  }
  .disclaimer {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    span a {
      color: ${colors.orange};
      text-decoration: underline;
    }
  }
  .submit {
    button {
      ${font.button};
    }
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 3.8rem;
  display: flex;
  flex-direction: column;
  &.last {
    margin-bottom: 2rem;
  }
  label {
    ${font.label};
    margin: 0;
  }
  textarea,
  input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid ${colors.greyLight};
    padding: 0.2rem 0;
    height: 2.4rem;
    font-size: 1.4rem;
    line-height: 125%;
    background: white;
    border-radius: 0;
  }
`;

const ErrorText = styled.div`
  background: ${colors.error};
  color: white;
  ${font.p};
  padding: 1.2rem;
  margin-bottom: 4rem;
  span {
    ${fontOffset};
  }
`;

export default function Form() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [canContact, setCanContact] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { openModal } = useContext(ModalContext);

  function handleSuccess() {
    setName('');
    setPhone('');
    setEmail('');
    setMessage('');
    setCanContact(true);
    setLoading(false);
    setError(false);
    openModal();
  }

  function handleError() {
    setLoading(false);
    setError(true);
  }

  async function handleSubmit(e) {
    if (name && phone && email) {
      e.preventDefault();
      setLoading(true);
      const data = {
        name,
        phone,
        email,
        message,
        canContact,
      };
      try {
        await axios({
          method: 'post',
          url: contact,
          data,
        });
        if (canContact)
          await axios({
            method: 'post',
            url: mailchimp,
            data,
          });
        handleSuccess();
      } catch (error) {
        handleError();
      }
    }
  }
  return (
    <Wrapper onSubmit={handleSubmit} className={loading ? 'loading' : ''}>
      {error && (
        <ErrorText>
          <span>
            Oops. Something went wrong! Please wait a few minutes and try again.
          </span>
        </ErrorText>
      )}
      <InputWrapper>
        <label htmlFor="inquire_name">Full Name</label>
        <input
          onChange={(e) => setName(e.target.value)}
          value={name}
          id="inquire_name"
          type="text"
          required
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="inquire_phone">Phone Number</label>
        <input
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          id="inquire_phone"
          type="tel"
          required
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="inquire_email">Email</label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          id="inquire_email"
          type="email"
          required
        />
      </InputWrapper>
      <InputWrapper className="last">
        <label htmlFor="inquire_message">Message</label>
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          id="inquire_message"
        />
      </InputWrapper>
      <div className="checker">
        <div className="check-img">
          <button type="button" onClick={() => setCanContact(!canContact)}>
            <img
              src={canContact ? CheckSrc : UncheckSrc}
              alt="Toggle signing up for Penrose email list"
            />
          </button>
        </div>
        <div className="check-text">
          <span>I want to receive updates and newsletter from PENROSE.</span>
        </div>
      </div>
      <div className="disclaimer">
        <span>
          By clicking submit, you agree to the{' '}
          <Link to="/terms">terms and conditions.</Link>
        </span>
      </div>
      <div className="submit">
        <button type="submit">
          <span>Inquire</span>
        </button>
      </div>
    </Wrapper>
  );
}
